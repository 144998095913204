import Styled from 'styled-components'
import Logo from '../asset/logo.png'
import Tile1 from '../asset/tile1.png'
import Tile2 from '../asset/tile2.png'

const Background = Styled.div`
    min-width:1240px;
    min-height:100vh;
    background-image:url(${Logo});
    background-repeat:no-repeat;
    background-size:65%;
    background-position: right -165px center; 
`
const BackgroundInner = Styled.div`
    min-width:1240px;
    min-height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
`;
const Container = Styled.div`
    display:flex;
    max-width:1240px;
    width:100%;
    justify-content: space-between;
    align-items:center;
  
`;
const TextContainer = Styled.div`
    display:flex;
    width:100%;
`;
const TextContainerInner = Styled.div`
    display:flex;
    flex-direction: column;
    padding: 0 50px;
`;
const Title = Styled.div`
    font-weight: 700;
    font-size: 120px;
    color:${({theme}) => theme.white};
    padding:5px 0; 
`;
const SubTitle = Styled.div`
    font-weight: 500;
    font-size: 26px;
    color:${({theme}) => theme.white};
    padding:15px 0; 
`;
const Description = Styled.div`
    font-weight: 300;
    font-size: 16px;
    color:${({theme}) => theme.mediumGrey};
    line-height: 180%;
    padding:10px 0; 
`;
const ComingSoonStyle = Styled.div`
    width: 281px;
    height: 40px;
    background: linear-gradient(269.96deg, #1DCDFE 24.77%, #34F5C5 113.53%);
    box-shadow: 0px 4px 5px rgba(22, 47, 74, 0.14), 0px 1px 10px rgba(22, 47, 74, 0.12), 0px 2px 4px rgba(22, 47, 74, 0.12);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    color:${({theme}) => theme.mediumNavy};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin:10px 0; 
`;
const TileOne = Styled.img`
  position:absolute;
  right: 10%;
  bottom: 400px;
  transform: rotate(-15deg);
`;
const TileTwo = Styled.img`
    position: absolute;
    right: 5%;
    bottom: 50px;
    transform: rotate(-15deg);
`;

const ComingSoon = () => {
    return(
        <Background>
            <TileOne src={Tile1}/>
            <TileTwo src={Tile2}/>
            <BackgroundInner>
                <Container>
                    <TextContainer>
                        <TextContainerInner>
                            <Title>Crossfire</Title>
                            <SubTitle>Crossfire is a trading venue-as-a-service.</SubTitle>
                            <Description> Out of the box technology you can use to setup your own exchange,<br/>list your products for auction or trading and build a market.</Description>
                            <ComingSoonStyle>Coming Soon</ComingSoonStyle>
                        </TextContainerInner>
                    </TextContainer>
                </Container>
            </BackgroundInner>
        </Background>
    )
}

export default ComingSoon;