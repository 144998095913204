import { ThemeProvider } from 'styled-components';
import { GlobalStyle, Theme } from './theme';
import Router from './routes';

const App = () => {
  return(
    <ThemeProvider theme={Theme}>
    <GlobalStyle />
      <Router/>
    </ThemeProvider>
    
  )
}
export default App;
