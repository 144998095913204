import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    body {
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-width:1240px;
    }
    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    #crossfire{
        background: #2F455C;
        height:100%;
        width:100%;
        padding:0;
        margin:0;
        position:relative;
        min-height: 100vh;
    }
`;

export const Theme = {
    primaryNavy:"#2F455C",
    mediumNavy: "#223952",
    darkNavy:"#132B43",
    aquaBlue: "#1dcdfe",
    aquaGreen: "#34F5C5",
    darkGrey: "#AABDD5",
    brightBlue: "#16A8D1",
    white:"#ffffff",
    mediumGrey:"#CFDCF6"
}